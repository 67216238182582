/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { parse, ParsedUrlQuery } from 'querystring';
import { useMemo, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { StatusCode, PaymentType } from '@common/constants';
import { Request } from '@common/requests';
import { useStore } from '@stores/useStore';
import { extractErrorStatus, extractAxiosErrorResponse } from '@utils/network';
import { adyenInit } from '@views/PaymentMethods/adyen';
import { InitiatePaymentRequestType, InitRequest } from '@views/PaymentMethods/types';

// A custom hook that builds on useLocation to parse the query strins.
// TODO: add generic like `<R extends Record<string, string | string[]>>(): R`
export const useQuery = (): ParsedUrlQuery => {
  const { search } = useLocation();
  const normalizedSearch = search.replace(/^\?/, '');

  return useMemo(() => parse(normalizedSearch), [normalizedSearch]);
};

export const useFragment = (): ParsedUrlQuery => {
  const { hash } = useLocation();
  const normalizedFragment = hash.replace(/^#/, '');

  return useMemo(() => parse(normalizedFragment), [normalizedFragment]);
};

type AccordionHook = [string | number, (id: string | number) => void];
export const useAccordion = (initialExpanded: string | number = ''): AccordionHook => {
  const [expandedItem, setExpandedItem] = useState(initialExpanded);

  const toggleMethod = useCallback(
    (name: string) => setExpandedItem(name === expandedItem ? '' : name),
    [expandedItem]
  );

  return [expandedItem, toggleMethod];
};

export const useFetchFundingContracts = () => {
  const [isWithError, setIsWithError] = useState<boolean>(false);
  const { fundingContract, billingAddress } = useStore();

  // NOTE(LiamC): The BFF will call to payments with an explicit funding contract fund type filter of `CARD`.
  // `WALLET` will never come back unless the BFF is altered.
  const fetchFundingContracts = async (orderId: string) => {
    try {
      const response = await Request.fetchFundingContracts(orderId);

      if (response.length && !fundingContract.changePaymentMethod) {
        fundingContract.addFundingContracts(response);
        billingAddress.setBillingAddressReference(
          fundingContract.getMostRecentFundingContract()?.billingAddressReference
        );
      }
    } catch (error) {
      const status = extractErrorStatus(extractAxiosErrorResponse(error));
      if (status === StatusCode.Unauthorized) {
        try {
          const { loginLink } = await Request.getLoginLink();
          window.location.assign(loginLink);
        } catch (err) {
          setIsWithError(true);
        }
      } else {
        setIsWithError(true);
      }
    }
  };

  return { fetchFundingContracts, isWithError };
};

export const useInitiatePayment = () => {
  const sessionBillingAddressReference = sessionStorage.getItem('billingAddressReference');
  const [data, setData] = useState<InitRequest>();
  const [isCurrencyChangedAlertShown, setIsCurrencyChangedAlertShown] = useState<boolean>(false);
  const [isWithError, setIsWithError] = useState<boolean>(false);

  const initiatePayment = (
    orderId: string,
    paymentType: InitiatePaymentRequestType,
    fundingContractReference?: string | undefined
  ) => {
    const initiateCheckoutReqBody = {
      paymentType: InitiatePaymentRequestType.CHECKOUT,
      billingAddressReference: sessionBillingAddressReference,
      fundingContractReference: fundingContractReference || undefined,
    };

    const initiateAdyenRequestBody = {
      paymentType: InitiatePaymentRequestType.ADYEN,
    };

    const reqBody =
      paymentType === InitiatePaymentRequestType.ADYEN ? initiateAdyenRequestBody : initiateCheckoutReqBody;

    Request.initiatePayment(orderId as string, reqBody)
      .then(async ({ data: paymentData, headers }) => {
        const methods = await adyenInit(paymentData.config);

        if (headers['cf-ipcountry'] !== 'US') {
          setIsCurrencyChangedAlertShown(true);
        }

        setData({ ...paymentData, ...{ methods } });
      })
      .catch((error) => {
        const status = extractErrorStatus(extractAxiosErrorResponse(error));

        if (status === StatusCode.Unauthorized) {
          Request.getLoginLink()
            .then(({ loginLink }) => {
              window.location.assign(loginLink);
            })
            .catch(() => {
              setIsWithError(true);
            });
        } else {
          setIsWithError(true);
        }
      });
  };

  return {
    initiatePayment,
    data,
    setData,
    isWithError,
    setIsWithError,
    isCurrencyChangedAlertShown,
    setIsCurrencyChangedAlertShown,
  };
};

export const useHandlePaymentError = (orderId: string) => {
  const [paymentErrorMessage, setPaymentErrorMessage] = useState<string | null>();
  const { initiatePayment } = useInitiatePayment();

  const handlePaymentError = (paymentMethod: PaymentType, message: string) => {
    // show error message
    setPaymentErrorMessage(message);
    // any checkout (ep-form) payments that error are EOL and can't be re-used
    // need to re-initialise the payment - same behaviour as Arcade
    if (PaymentType.checkout === paymentMethod) {
      initiatePayment(orderId, InitiatePaymentRequestType.CHECKOUT);
    }
  };

  return { handlePaymentError, paymentErrorMessage, setPaymentErrorMessage };
};
