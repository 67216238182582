/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import styled from '@emotion/styled';
import { sizes } from '@jagex-pp/jds';

export const PageContainer = styled.div`
  width: 100%;
  gap: ${sizes.s16.px};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
