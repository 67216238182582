/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { AxiosRequestConfig } from 'axios';
import { BillingAddressResponse, BillingAddress } from '@components/CheckoutForm/types';
import { api, extractResponse } from '@utils/network';

export const Requests = {
  verifyBillingAddress: (requestData: BillingAddress, config?: AxiosRequestConfig): Promise<BillingAddressResponse> => {
    return extractResponse<BillingAddressResponse>(api.post('/addresses/validate', requestData, config));
  },
};
