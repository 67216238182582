/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { injectable } from 'inversify';
import { action, makeObservable } from 'mobx';
import { withAuth } from '../Auth/decorators';
import { Requests } from './Requests';

@injectable()
export class Checkout {
  constructor() {
    makeObservable(this, {
      createCheckoutSession: action,
    });
  }

  @withAuth
  async createCheckoutSession(planId: string): Promise<string> {
    const response = await Requests.createCheckoutSession(planId);

    return response.checkoutUrl;
  }
}
