/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import styled from '@emotion/styled';
import { colors, device, sizes } from '@jagex-pp/jds';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  width: 100%;
  height: 100%;
`;

export const PageContent = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: ${colors.white};
  margin: 1rem 25px;

  @media screen and (${device.xsmall}) {
    margin: ${sizes.s24.px} ${sizes.s24.px};
  }

  @media screen and (${device.small}) {
    margin: ${sizes.s24.px} ${sizes.s48.px};
  }

  @media screen and (${device.medium}) {
    margin: ${sizes.s96.px} ${sizes.s128.px};
  }

  @media screen and (${device.large}) {
    margin: ${sizes.s96.px} ${sizes.s256.px};
  }
`;
