/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { Locale } from '@i18n/types';
import { LOCALE_REGEX, LANGUAGE_REGEX } from './config';

export const setLocaleIntoSessionStorage = (language: string): void => {
  sessionStorage.setItem('language', language);
};

export const extractLocaleFromSessionStorage = (): Locale | null => {
  const language = sessionStorage.getItem('language') as Locale;
  sessionStorage.removeItem('language');

  return language;
};

export const matchesFormat = (str: string, regex: RegExp): string | null => {
  const matches = str.match(regex);

  return matches ? matches[0] : null;
};

export const matchesLocaleFormat = (localeStr: string): string | null => {
  return matchesFormat(localeStr, LOCALE_REGEX);
};

export const matchesLanguageFormat = (localeStr: string): string | null => {
  return matchesFormat(localeStr, LANGUAGE_REGEX);
};

export const extractLocaleFromURL = (): Locale | null => {
  if (window) {
    const { pathname } = window.location;
    const language = matchesLocaleFormat(pathname) || matchesLanguageFormat(pathname);

    if (language) {
      return language.replace('/', '') as Locale;
    }
  }

  return null;
};

export const extractLocaleFromQueryParams = (): Locale | null => {
  if (window) {
    const { search } = window.location;

    return new URLSearchParams(search).get('lang') as Locale;
  }

  return null;
};
