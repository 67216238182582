/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { Loader, Heading, Link } from '@jagex-pp/jds';
import React, { FunctionComponent, useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthStatus } from '@common/constants';
import { Request } from '@common/requests';
import { PageContent, Container } from '@components/Layout/styled';
import { useQuery } from '@utils/hooks';
import { getLastTargetRoute } from '@utils/navigation';

export const AuthHandler: FunctionComponent = () => {
  const [isError, setIsError] = useState(false);
  const [authStatus] = useState(AuthStatus.UNKNOWN);
  const { lang } = useParams<{ lang: string }>();
  const query = useQuery();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onSuccess = useCallback(() => {
    const lastTargetRoute = getLastTargetRoute();

    navigate(lastTargetRoute ?? '/');
  }, [navigate]);

  useEffect(() => {
    const { code, error, error_hint } = query;
    if (error || error_hint || !code) {
      setIsError(true);
    } else {
      if (authStatus !== AuthStatus.AUTHORIZED) {
        Request.getOAuthTokens(query)
          .then(onSuccess)
          .catch(() => setIsError(true));
      } else {
        onSuccess();
      }
    }
  }, [authStatus, navigate, lang, onSuccess, query]);

  return (
    <Container>
      <PageContent style={{ marginTop: '160px' }}>
        {isError ? (
          <div>
            <div>
              <Heading id="auth-handler-error-message">{t('errorPage:commonNotification')}</Heading>
            </div>
            <div>
              <Link href="/" variant="primary" data-testid="auth-handler-error-back-button">
                {t('errorPage:retry')}
              </Link>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </PageContent>
    </Container>
  );
};
