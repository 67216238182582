/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { AxiosRequestConfig } from 'axios';
import { injectable } from 'inversify';
import { action, makeObservable, observable } from 'mobx';
import { withAuth } from '@stores/domains/Auth/decorators';
import { Requests } from './Requests';
import { CreateSkuRequest, CreateSkuResponse, GetOrderDataRequest, OrderData } from './types';

@injectable()
export class Order {
  orderData: OrderData | null = null;

  constructor() {
    makeObservable(this, {
      orderData: observable,
      setOrderData: action,
    });
  }

  setOrderData(orderData: OrderData): void {
    this.orderData = orderData;
  }

  @withAuth
  async getOrderData(skuKey: string, params: GetOrderDataRequest, config: AxiosRequestConfig = {}): Promise<void> {
    const orderDataInfo = await Requests.getOrderData(skuKey, params, config);
    this.setOrderData(orderDataInfo);
  }

  @withAuth
  async createSkuOrder(body: CreateSkuRequest, config: AxiosRequestConfig = {}): Promise<CreateSkuResponse> {
    return await Requests.createSkuOrder(body, config);
  }
}
