/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { ParsedUrlQuery } from 'querystring';
import { AxiosRequestConfig } from 'axios';
import { api, extractResponse } from '@utils/network';
import { LoginLinkRequest, LogoutResponse } from './types';

export const Requests = {
  checkAuthStatus: (config?: AxiosRequestConfig): Promise<void> => api.get('/sessions', config),
  getLoginLink: (config?: AxiosRequestConfig): Promise<LoginLinkRequest> =>
    extractResponse<LoginLinkRequest>(api.get('/oauth/link', config)),
  logout: (config?: AxiosRequestConfig): Promise<LogoutResponse> =>
    extractResponse(api.post('/oauth/logout', {}, config)),
  getOAuthTokens: (body: ParsedUrlQuery, config?: AxiosRequestConfig): Promise<void> =>
    extractResponse(api.post('/oauth/token', body, config)),
};
