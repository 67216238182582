/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { injectable } from 'inversify';
import { action, observable, makeObservable } from 'mobx';
import { BillingAddressResponse } from '@components/CheckoutForm/types';
import { Requests } from '@stores/domains/BillingAddress/Requests';

@injectable()
export class BillingAddress {
  billingAddress: any | null = null;
  billingAddressReference: string | undefined = undefined;

  constructor() {
    makeObservable(this, {
      billingAddress: observable,
      setBillingAddress: action,
      billingAddressReference: observable,
      setBillingAddressReference: action,
    });
  }

  setBillingAddress(billingAddress: any): void {
    this.billingAddress = billingAddress;

    if (billingAddress) sessionStorage.setItem('billingAddress', JSON.stringify(billingAddress));
  }

  setBillingAddressReference(billingAddressReference: string | undefined): void {
    this.billingAddressReference = billingAddressReference;

    if (billingAddressReference) sessionStorage.setItem('billingAddressReference', billingAddressReference);
  }

  async verifyBillingAddress(): Promise<BillingAddressResponse> {
    const verifyBillingAddressResponse = await Requests.verifyBillingAddress(this.billingAddress);
    this.setBillingAddress(verifyBillingAddressResponse.billingAddressInfo);
    this.setBillingAddressReference(verifyBillingAddressResponse.billingAddressReference?.billingAddressReference);

    return verifyBillingAddressResponse;
  }
}
