/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import React, { FunctionComponent, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Loader } from '@components/Loader';
import { getLastTargetRoute, clearTargetRoute } from '@utils/navigation';
import { PageContainer } from './styled';

export const RestoreRoute: FunctionComponent = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const lastTargetRoute = getLastTargetRoute();
    clearTargetRoute();
    navigate(lastTargetRoute ?? '/');
  }, [navigate]);

  return (
    <PageContainer>
      <Loader />
    </PageContainer>
  );
};
