/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { CoreOptions } from '@adyen/adyen-web/dist/types/core/types';
import { BasketItem } from '@components/Basket/types';
import { BillingDetails, BillingAddress } from '@components/CheckoutForm/types';

export interface CheckoutConfig {
  id: string;
  embeddedDOMElement: any;
}

export type AdyenInstance = CoreOptions & {
  create?: any;
  id?: string;
  returnUrl?: string;
};

export interface PaymentConfig extends CoreOptions {
  backToShopUrl?: string;
  checkoutUrl?: string;
  locale: string;
  adyen: AdyenInstance;
  checkout?: CheckoutConfig;
  returnUrl?: string;
}

export interface InitializePaymentData {
  displayName?: string;
  totalPrice: string;
  taxAmount?: string;
  basket: BasketItem[];
  config: PaymentConfig;
  billingDetails: BillingDetails;
  paymentId: string;
  billingAddressInfo?: BillingAddress;
}

export interface InitRequest extends InitializePaymentData {
  methods: AdyenInstance[];
}

export enum InitiatePaymentRequestType {
  ADYEN = 'ADYEN',
  CHECKOUT = 'CHECKOUT',
}

export interface InitiatePaymentRequestBody {
  paymentType: InitiatePaymentRequestType;
  billingAddressReference?: string;
  fundingContractReference?: string;
}
