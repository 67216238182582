/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { AxiosRequestConfig } from 'axios';
import { api, extractResponse } from '@utils/network';
import { CheckoutResponse } from './types';

export const Requests = {
  createCheckoutSession: async (planId: string, config: AxiosRequestConfig = {}): Promise<CheckoutResponse> =>
    extractResponse<CheckoutResponse>(api.post('/checkout', { planId }, config)),
};
