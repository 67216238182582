/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { Container } from 'inversify';
import { Localisation, User, BillingAddress, Order, Auth, FundingContract, Checkout } from '@stores/domains';
import { TYPES } from '@stores/types';

export const container = new Container({ defaultScope: 'Singleton' });

container.bind<Localisation>(TYPES.Localisation).to(Localisation);
container.bind<User>(TYPES.User).to(User);
container.bind<BillingAddress>(TYPES.BillingAddress).to(BillingAddress);
container.bind<Order>(TYPES.Order).to(Order);
container.bind<Auth>(TYPES.Auth).to(Auth);
container.bind<FundingContract>(TYPES.FundingContract).to(FundingContract);
container.bind<Checkout>(TYPES.Checkout).to(Checkout);
