/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import 'reflect-metadata';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18n } from '@i18n/i18n';
import { createStore } from '@stores/createStore';
import { api } from '@utils/network';
import App from './App';

const store = createStore();
const i18n = new I18n(api);

if (process.env.NODE_ENV === 'development') {
  const runServer = async () => {
    const mock = await import('../mocks');
    mock.runServer();
    ReactDOM.render(<App store={store} i18nInstance={i18n} />, document.getElementById('root'));
  };
  runServer();
} else {
  ReactDOM.render(<App store={store} i18nInstance={i18n} />, document.getElementById('root'));
}
