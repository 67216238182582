/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { AxiosRequestConfig } from 'axios';
import { api, extractResponse } from '@utils/network';
import { UserCurrentResponse } from './types';

export const Requests = {
  current: (options: AxiosRequestConfig = {}): Promise<UserCurrentResponse> => {
    return extractResponse<UserCurrentResponse>(api.get('/users/current', options));
  },
};
