/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import React, { useEffect, useState, FunctionComponent } from 'react';
import { Request } from '@common/requests';
import { Layout } from '@components/Layout';
import { Container, PageContent } from '@components/Layout/styled';
import { Loader } from '@components/Loader';
import { RedirectRoute } from '@components/RedirectRoute';
import { useStore } from '@stores/useStore';

export const ProtectedDataRoute: FunctionComponent = () => {
  const { user } = useStore();
  const [status, setStatus] = useState('loading');

  useEffect(() => {
    const getData = async () => {
      try {
        await Request.checkAuthStatus();
        await user.current();
        setStatus('loaded');
      } catch {
        setStatus('error');
      }
    };
    getData();
  }, [user]);

  return status === 'loaded' && user.info ? (
    <Layout userInfo={user.info} />
  ) : status === 'error' ? (
    <RedirectRoute />
  ) : (
    <Container>
      <PageContent style={{ marginTop: '160px' }}>
        <Loader />
      </PageContent>
    </Container>
  );
};
