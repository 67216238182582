/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { AxiosRequestConfig, AxiosInstance } from 'axios';
import { LocaleResponse } from '@i18n/types';
import { extractResponse } from '@utils/network';

export const Request = {
  translation: (api: AxiosInstance, config?: AxiosRequestConfig): Promise<any> =>
    extractResponse<LocaleResponse>(api.get('/locale', config)),
};
