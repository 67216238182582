/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import styled from '@emotion/styled';
import { sizes, typography } from '@jagex-pp/jds';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${sizes.s48.px} 0;
  gap: ${sizes.s48.px};
  text-align: center;
  font-size: ${typography.sizes['18']};
`;
