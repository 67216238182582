/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { AxiosInstance } from 'axios';
import { Location } from 'history';
import { Request } from '@stores/domains/Localisation/Requests';
import { locConfig } from './config';
import { Options, Locale } from './types';
import {
  extractLocaleFromSessionStorage,
  extractLocaleFromURL,
  setLocaleIntoSessionStorage,
  extractLocaleFromQueryParams,
} from './utils';

export class I18nFacade {
  static detectUserLocale(): Locale | null {
    return extractLocaleFromSessionStorage() ?? extractLocaleFromURL() ?? extractLocaleFromQueryParams() ?? null;
  }

  static async getTranslations(api: AxiosInstance, code?: string): Promise<any> {
    const config: Options = {
      resources: {},
      fallbackLng: [],
    };
    const { lng, resources } = await I18nFacade.loadSelectedTranslation(api, code);

    if (lng) {
      config.lng = lng;
      config.resources = resources;
    }

    return { ...locConfig, ...config };
  }

  static async loadSelectedTranslation(api: AxiosInstance, code?: string): Promise<any> {
    const params = code ? { code } : {};
    return Request.translation(api, { params });
  }

  static getPathWithCorrectLocale({ pathname, search }: Location, newLocale: string): string | null {
    const locale = extractLocaleFromURL();

    if (locale === newLocale) {
      return null;
    } else {
      const updatedPath = locale ? pathname.replace(/^\/[\w-]{2,5}/, newLocale) : `${newLocale}${pathname}`;

      return `/${updatedPath}${search}`;
    }
  }

  static saveCurrentLocale(locale: string): void {
    setLocaleIntoSessionStorage(locale);
  }
}
