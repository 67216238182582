/*
 * Copyright 1999-2025 Jagex Ltd.
 */
export const TYPES = {
  User: Symbol('User'),
  Localisation: Symbol('Localisation'),
  BillingAddress: Symbol('BillingAddress'),
  Order: Symbol('Order'),
  Auth: Symbol('Auth'),
  FundingContract: Symbol('FundingContract'),
  Checkout: Symbol('Checkout'),
};
